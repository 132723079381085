@import "../_westfunk";

body, html {
	font-family: "eurostile-condensed", sans-serif;  
}

h1, h2, h3, h4, h5, h6 {
	font-family: "eurostile-condensed", sans-serif;
	font-weight: 800;    
}

p, a, li, span, div {
	font-family: "eurostile-condensed", sans-serif;  
}

.nav .nav-logo--large img {
    height: 10.75rem;
}

